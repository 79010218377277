import { Edit, MoreHoriz, Save } from '@mui/icons-material'
import {
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TableSortLabel,
  styled,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  transitionToDenied,
  transitionToPending,
  transitionToVerified,
} from '../../clients/TrainerVerificationClient'
import { Trainer, TrainerVerificationStatus } from '../../models/Trainer'
import LoadingPage from '../shared/LoadingPage'
import { useAdminGetTrainers } from './hooks/AdminUseTrainersHook'
import TrainerForAdminView from './models/TrainerForAdminView'

type Order = 'asc' | 'desc'

// Custom styled Select to make it fit the word length
const StyledSelect = styled(Select)({
  width: 'auto',
  minWidth: '100px', // Reduced width for a shorter dropdown
  '& .MuiSelect-select': {
    padding: '8px 12px',
  },
})

const AdminTrainers = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [orderBy, setOrderBy] = useState<keyof TrainerForAdminView>('createdAt')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const { data: fetchedTrainers, isLoading, refetch } = useAdminGetTrainers()
  const [editingTrainer, setEditingTrainer] = useState<string | null>(null)
  const [newStatus, setNewStatus] = useState<string>('')

  const visibleRows = useMemo(
    () =>
      [...(fetchedTrainers || [])]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, fetchedTrainers]
  )

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (a: TrainerForAdminView, b: TrainerForAdminView) => number {
    return order === 'desc'
      ? (a, b) =>
          descendingComparator(a, b, orderBy as keyof TrainerForAdminView)
      : (a, b) =>
          -descendingComparator(a, b, orderBy as keyof TrainerForAdminView)
  }

  const handleRequestSort = (
    _: React.MouseEvent,
    property: keyof TrainerForAdminView
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  // Handler to begin editing the status
  const handleEditStatusChange = (trainer: TrainerForAdminView) => {
    setEditingTrainer(trainer.trainerUuid)
    setNewStatus('')
  }

  // Handler to save the new status
  const handleSaveStatusChange = async (
    trainer: Trainer,
    newStatus: string
  ) => {
    if (!fetchedTrainers) return

    const currentStatus = trainer.verificationStatus

    // Exit if no change in status
    if (newStatus === currentStatus) {
      setEditingTrainer('')
      setNewStatus('')
      return
    }

    try {
      // Call the appropriate transition function
      let response
      switch (newStatus) {
        case TrainerVerificationStatus.VERIFIED:
          response = await transitionToVerified(trainer.trainerUuid)
          break
        case TrainerVerificationStatus.PENDING:
          response = await transitionToPending(trainer.trainerUuid)
          break
        case TrainerVerificationStatus.DENIED:
          response = await transitionToDenied(trainer.trainerUuid)
          break
        default:
          throw new Error(`Invalid status: ${newStatus}`)
      }

      if (!response.ok) {
        throw new Error('Failed to update status')
      }

      console.log(`Successfully updated status for ${trainer.trainerUuid}`)

      // Trigger refetch to update data
      await refetch()
    } catch (error) {
      console.error('Error updating status:', error)
      // No need to handle optimistic update rollback since refetch resets data
    } finally {
      setEditingTrainer('')
      setNewStatus('')
    }
  }

  const handleStatusSelectChange = (event: SelectChangeEvent<unknown>) => {
    setNewStatus(event.target.value as string)
  }

  if (isLoading) {
    return <LoadingPage />
  }

  if (!fetchedTrainers) {
    return <div>No trainers found</div>
  }

  const statusOptions = Object.values(TrainerVerificationStatus).map(
    (status) => (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    )
  )

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontSize: '1.2rem' }}>
                Trainer Name
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  direction={orderBy === 'createdAt' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'createdAt')}
                >
                  Date Created
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
                <TableSortLabel
                  active={orderBy === 'canTrain'}
                  direction={orderBy === 'canTrain' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'canTrain')}
                >
                  Can Train
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
                <TableSortLabel
                  active={orderBy === 'verificationStatus'}
                  direction={orderBy === 'verificationStatus' ? order : 'asc'}
                  onClick={(event) =>
                    handleRequestSort(event, 'verificationStatus')
                  }
                >
                  Verification Status
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((trainer) => (
              <TableRow
                key={trainer.trainerUuid}
                hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell align="left" sx={{ fontSize: '1.2rem' }}>
                  {trainer.account.firstName} {trainer.account.lastName}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
                  {new Date(trainer.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
                  {trainer.canTrain ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '1.2rem' }}>
                  {editingTrainer === trainer.trainerUuid ? (
                    <FormControl>
                      <StyledSelect
                        value={newStatus || trainer.verificationStatus}
                        onChange={handleStatusSelectChange}
                      >
                        {statusOptions}
                      </StyledSelect>
                    </FormControl>
                  ) : (
                    trainer.verificationStatus
                  )}
                </TableCell>
                <TableCell align="right">
                  {editingTrainer === trainer.trainerUuid ? (
                    <>
                      {/* Save Button */}
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleSaveStatusChange(trainer, newStatus)
                        }
                      >
                        <Save />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      {/* Edit Button */}
                      <IconButton
                        color="primary"
                        onClick={() => handleEditStatusChange(trainer)}
                      >
                        <Edit />
                      </IconButton>
                    </>
                  )}
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      navigate(`/admin/trainers/${trainer.trainerUuid}`)
                    }
                  >
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={fetchedTrainers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AdminTrainers

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const ABOUT_CHARACTER_LIMIT = 500

export const MAX_FILE_SIZE = 100 * 1024 * 1024 // 100MB

export const MAX_NUM_VIDEOS = 10

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Booking from '../../models/Booking'

dayjs.extend(utc)

const bookingInXDays = (booking: Booking) => {
  return Math.ceil(
    utcToLocalDate(booking.startDatetime)
      .startOf('day')
      .diff(dayjs(), 'days', true)
  )
}

const utcToLocalDate = (datetime: Date) => {
  return dayjs.utc(datetime).local()
}

export { bookingInXDays }

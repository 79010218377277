import { useQuery } from '@tanstack/react-query'
import { getPopularTrainers, getTrainer } from '../../clients/TrainerClient'
import { Trainer } from '../../models/Trainer'
import { TrainerProfile } from '../../models/TrainerProfile'

// Hook to fetch a trainer by their trainerUuid
const useGetTrainerByUuid = (trainerUuid: string) => {
  return useQuery<Trainer | undefined, Error>({
    queryKey: ['trainer', trainerUuid],
    queryFn: async () => {
      const response = await getTrainer(trainerUuid)
      if (!response.ok) {
        throw new Error('Failed to fetch trainer')
      }
      const data = await response.json()
      return data as Trainer
    },
    enabled: !!trainerUuid,
  })
}

const useGetPopularTrainers = () => {
  return useQuery<TrainerProfile[], Error>({
    queryKey: ['popular-trainers'],
    queryFn: async () => {
      const response = await getPopularTrainers()
      if (!response.ok) {
        throw new Error('Failed to fetch popular trainers')
      }
      const data = await response.json()

      const trainerProfiles = data.trainers.map((trainer: TrainerProfile) => {
        return new TrainerProfile({
          ...trainer,
        })
      })

      return trainerProfiles
    },
    retry: 0,
  })
}

export { useGetPopularTrainers, useGetTrainerByUuid }

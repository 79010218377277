import { useQuery } from '@tanstack/react-query'
import { Position } from 'geojson'
import { getTrainersByLocation } from '../../clients/TrainerSearchClient'
import { TrainerProfile } from '../../models/TrainerProfile'

export const useGetTrainersByLocation = (
  position: Position,
  radius?: number
) => {
  return useQuery<TrainerProfile[], Error>({
    queryKey: ['trainers-by-location', position, radius],
    queryFn: async () => {
      const response = await getTrainersByLocation(position, radius)
      if (!response.ok) {
        throw new Error('Failed to fetch trainers by location')
      }
      const data = await response.json()

      return data.trainers.map(
        (trainerProfile: TrainerProfile) =>
          new TrainerProfile({
            ...trainerProfile,
          })
      )
    },
    enabled: position && position[0] !== 0 && position[1] !== 0,
    retry: 1,
  })
}

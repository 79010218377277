enum EmailVerificationStatus {
  VERIFIED = 'verified',
  PENDING = 'pending',
}

interface User {
  id: number
  email: string
  isSuperuser: boolean
  emailVerificationStatus: EmailVerificationStatus
}

export { EmailVerificationStatus }

export default User

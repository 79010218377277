import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ScheduleIcon from '@mui/icons-material/Schedule'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import VerifiedIcon from '@mui/icons-material/Verified'

import { Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LearnerHome from '../LearnerHome'
import TrainerHome from '../TrainerHome'
import { useAccount } from '../auth/AuthProvider'
import { useGetPopularTrainers } from '../queries/UseTrainersHook'
import LoadingPage from '../shared/LoadingPage'
import { useProgressiveImage } from '../shared/UseProgressiveImage'
import HowItWorks from './HowItWorks'

const CLOUD_FRONT_LADINGPAGE = import.meta.env.VITE_CLOUDFRONT_DOMAIN

const HeroSection = styled(Box)({
  position: 'relative',
  minHeight: '85vh',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  color: '#fff',
  width: '100%',
  overflow: 'visible',
  '@media (max-width: 600px)': {
    minHeight: '85vh',
    paddingTop: '10vh',
  },
})

const TrainerCardContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  gap: 16,
  padding: '16px 0',
  position: 'relative',
  width: '100%',
  maxWidth: '100vw',
})

const TrainerCard = styled(Box)({
  width: 300,
  minWidth: 280,
  maxWidth: '90vw',
  height: 450,
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  borderRadius: 8,
  overflow: 'hidden',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  cursor: 'pointer',
  position: 'relative',
  flexShrink: 0,
})

const BlurOverlayLeft = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  width: 50,
  zIndex: 2,
  background:
    'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
})

const BlurOverlayRight = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: 50,
  zIndex: 2,
  background:
    'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
})

const SearchButton = styled(Button)({
  height: '64px',
  width: '256px',
  borderRadius: '24px',
  padding: '8px 16px',
  backgroundColor: '#84c3aa',
  color: '#3a3a3a',
  background: 'linear-gradient(rgba(161, 209, 189, 1), rgba(108, 171, 145, 1))',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: '#79bea2',
    marginTop: '-2px',
    marginLeft: '2px',
  },

  '@keyframes shine': {
    from: {
      WebkitMaskPosition: '150%',
    },
    to: {
      WebkitMaskPosition: '-50%',
    },
  },
  WebkitMaskImage:
    'linear-gradient(-75deg, rgba(0, 0, 0, .9) 30%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, .9) 70%)',
  WebkitMaskSize: '200%',
  animation: 'shine 2s infinite ease',
})

const FeatureCard = styled(Box)({
  padding: '20px',
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  borderRadius: 12,
  textAlign: 'center',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
})

const ChipStyled = styled(Chip)({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  borderRadius: '8px',
  px: 1,
  py: 0.5,
  maxWidth: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const EmpowerSection = () => (
  <Box sx={{ py: 8, backgroundColor: '#f7f7f7', textAlign: 'center' }}>
    <Container maxWidth="lg">
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: '#8EB695',
          letterSpacing: 1,
          mb: 1,
        }}
      >
        Our promises
      </Typography>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{
          mb: 2,
          color: '#333',
          fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
        }}
      >
        Empowering bowlers, one strike at a time
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{
          maxWidth: '600px',
          margin: '0 auto',
          fontSize: { xs: '0.875rem', sm: '1rem' },
          lineHeight: 1.6,
          color: '#666',
          mb: 6,
        }}
      >
        At StrikeTutor, we make professional bowling coaching accessible to
        everyone, helping bowlers at every level unlock their full potential
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <EmojiEventsIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Expert certified coaches
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Experienced coaches provide personalized training to match your
              skill level and goals
            </Typography>
          </FeatureCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <ScheduleIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Convenient scheduling
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Easily book, track, and reschedule lessons with just a few clicks
              on our user-friendly platform
            </Typography>
          </FeatureCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <SchoolIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Structured learning
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Our step-by-step coaching helps you improve consistently,
              regardless of your skill levels
            </Typography>
          </FeatureCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <LocationOnIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Community connections
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Find nearby bowling centers and connect with other bowlers in your
              area through StrikeTutor
            </Typography>
          </FeatureCard>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

const Home = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { account, isLoadingAccount } = useAccount()
  const { data: popularTrainers, isLoading } = useGetPopularTrainers()
  const loadedBackgroundImage = useProgressiveImage(
    `${CLOUD_FRONT_LADINGPAGE}/landing-page/BowlingBackgroundLeft.png`
  )

  const [isLoaded, setIsLoaded] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(true)

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth)
    }
  }

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', checkScrollPosition)
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', checkScrollPosition)
      }
    }
  }, [scrollRef.current])

  const handleSearch = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          navigate(
            `/trainers?lat=${position.coords.latitude}&lng=${position.coords.longitude}`
          )
        },
        () => {
          navigate('/trainers')
        }
      )
    } else {
      navigate('/trainers')
    }
  }

  if (isLoadingAccount) {
    return <LoadingPage />
  }

  if (account?.trainer) {
    return <TrainerHome />
  } else if (account?.learner) {
    return <LearnerHome />
  }

  return (
    <Box>
      <img
        src={loadedBackgroundImage}
        alt="background"
        loading="lazy"
        onLoad={() => setIsLoaded(true)}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          visibility: isLoaded ? 'visible' : 'hidden',
        }}
      />

      <HeroSection
        sx={{
          position: 'relative',
          zIndex: 10,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            position: 'relative',
            zIndex: 2,
            textAlign: 'left',
            color: 'white',
            paddingTop: '40vh',
            paddingBottom: { xs: '2rem', sm: 0 },
            '@media (max-width: 600px)': {
              paddingTop: '25vh',
            },
          }}
        >
          <Box
            sx={{
              '&MuiTypography-root': {
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
              },
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              Elevate your
              {isMobile ? <br /> : ' '}
              game with
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: '#C3E3D3',
              }}
              gutterBottom
            >
              professional bowlers
            </Typography>
          </Box>
          <SearchButton variant="contained" onClick={handleSearch}>
            <Typography variant="h6" textTransform="none" fontWeight="bold">
              Search coaches
            </Typography>
            <Search className="ml-2" />
          </SearchButton>
        </Container>
      </HeroSection>

      <Box
        sx={{
          py: 4,
          backgroundColor: 'white',
          textAlign: 'center',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Typography variant="h6" fontWeight="medium" gutterBottom>
          Partnering with certified coaches from
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Box
            component="img"
            src={`${CLOUD_FRONT_LADINGPAGE}/landing-page/PBALogo.svg`}
            alt="PBA Logo"
            sx={{ width: { xs: 140, sm: 200, md: 260 }, height: 'auto' }}
          />
          <Box
            component="img"
            src={`${CLOUD_FRONT_LADINGPAGE}/landing-page/PWBALogo.svg`}
            alt="PBA Logo"
            sx={{ width: { xs: 140, sm: 200, md: 260 }, height: 'auto' }}
          />
          <Box
            component="img"
            src={`${CLOUD_FRONT_LADINGPAGE}/landing-page/USBCLogo.svg`}
            alt="USBC Logo"
            sx={{ width: { xs: 140, sm: 200, md: 260 }, height: 'auto' }}
          />
        </Box>
      </Box>
      <Container maxWidth="lg" sx={{ py: 6, position: 'relative' }}>
        <BlurOverlayLeft />
        <IconButton
          onClick={() => scroll('left')}
          sx={{
            position: 'absolute',
            left: -40,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 3,
            opacity: canScrollLeft ? 1 : 0.3,
            pointerEvents: canScrollLeft ? 'auto' : 'none',
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>

        <TrainerCardContainer ref={scrollRef}>
          {isLoading ? (
            <CircularProgress />
          ) : popularTrainers && popularTrainers.length > 0 ? (
            popularTrainers.map((trainer) => (
              <TrainerCard
                key={trainer.trainerUuid}
                onClick={() => {
                  navigate(`/trainer/${trainer.trainerUuid}/profile`)
                }}
              >
                <Box
                  component="img"
                  src={trainer.profilePictureUrl}
                  alt={trainer.firstName}
                  sx={{ width: '100%', height: 200, objectFit: 'cover' }}
                />
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" fontWeight="bold">
                    {trainer.lastName}
                  </Typography>

                  <Box display="flex" gap={1} flexWrap="wrap" mb={1}>
                    {trainer.bowlingStyles?.map((type: string) => (
                      <ChipStyled
                        key={type}
                        label={type}
                        sx={{
                          bgcolor:
                            type === 'Two-handed'
                              ? '#AFFFE7'
                              : type === 'Stroker'
                                ? '#E2ECF6'
                                : type === 'Cranker'
                                  ? '#F6E2E2'
                                  : '#E2F6F1',
                        }}
                      />
                    ))}
                  </Box>

                  <Box display="flex" alignItems="center" gap={0.5} mb={1}>
                    <LocationOnIcon fontSize="small" />
                    {trainer.defaultBowlingCenter?.name}
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5} mb={1}>
                    <StarIcon sx={{ color: '#ffdf00' }} fontSize="small" />
                    {trainer.reviewCount > 0
                      ? `${trainer.averageRating.substring(0, 3)} (${trainer.reviewCount} review${trainer.reviewCount > 1 ? 's' : ''})`
                      : 'No ratings'}
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <VerifiedIcon fontSize="small" sx={{ color: 'green' }} />
                    {trainer.certifications}
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5} mt={1}>
                    <LocalAtmIcon fontSize="small" />${trainer.hourlyRate} per
                    session
                  </Box>
                </Box>
              </TrainerCard>
            ))
          ) : (
            <Typography
              variant="h6"
              color="textSecondary"
              align="center"
            ></Typography>
          )}
        </TrainerCardContainer>

        <BlurOverlayRight />
        <IconButton
          onClick={() => scroll('right')}
          sx={{
            position: 'absolute',
            right: -40,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 3,
            opacity: canScrollRight ? 1 : 0.3,
            pointerEvents: canScrollRight ? 'auto' : 'none',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Container>
      <EmpowerSection />
      <HowItWorks />
    </Box>
  )
}

export default Home

import {
  AccountCircleOutlined,
  ArrowDropDown,
  Close as CloseIcon,
  Login,
  LogoutOutlined,
  Menu as MenuIcon,
  PersonAddAlt1,
  PhotoCameraFrontOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import StrikeTutorLogoBlack from '../../assets/StrikeTutorLogoBlack'
import StrikeTutorLogoWhite from '../../assets/StrikeTutorLogoWhite'
import { useAccount } from '../auth/AuthProvider'

interface CustomButtonProps {
  url: string
  text: string
  isLandingPage: boolean
  sx?: object
}

const CustomButton: React.FC<CustomButtonProps> = ({
  url,
  text,
  isLandingPage,
  sx,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(url)
  }

  return (
    <Button
      onClick={handleClick}
      sx={{
        fontSize: { xs: '0.9rem', sm: '1rem' },
        fontWeight: 500,
        borderRadius: '8px',
        px: { xs: 1.5, sm: 2 },
        py: 0.75,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        minWidth: 'auto',
        backgroundColor: isLandingPage ? 'rgba(255, 255, 255, 0.1)' : '#1E140C',
        color: isLandingPage ? 'white' : 'white',
        '&:hover': {
          backgroundColor: isLandingPage
            ? 'rgba(255, 255, 255, 0.2)'
            : 'rgba(30, 20, 12, 0.85)',
        },
        ...sx,
      }}
    >
      {text}
    </Button>
  )
}

interface Account {
  trainer?: {
    trainerUuid: string
  }
  profilePictureUrl?: string
}

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { account, isLoadingAccount, doLogout } = useAccount() as {
    account: Account | null
    isLoadingAccount: boolean
    doLogout: () => void
  }
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isDropdownOpen = Boolean(anchorEl)

  // Only show landing page style when on root path AND not logged in AND not loading (last bit to prevent the landing page style from shwoing on reload)
  const isLandingPage =
    location.pathname === '/' && !account && !isLoadingAccount
  const isAuthPage =
    location.pathname === '/login' || location.pathname === '/signup'

  const isMobile = useMediaQuery('(max-width:600px)')
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    // Close both mobile menu and dropdown when location changes
    setMobileMenuOpen(false)
    setAnchorEl(null)
  }, [location])

  // Show only logo on auth pages
  if (isAuthPage) {
    return (
      <Box
        component="nav"
        sx={{
          position: 'relative',
          width: '100%',
          zIndex: 1100,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: { xs: '56px', sm: '64px' },
            px: { xs: 2, sm: 3 },
          }}
        >
          <NavLink to="/" className="flex items-center">
            <StrikeTutorLogoBlack
              sx={{ fontSize: { xs: '7.5rem', sm: '10rem' } }}
            />
          </NavLink>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      component="nav"
      sx={{
        position: isLandingPage ? 'fixed' : 'sticky',
        top: isLandingPage ? { xs: 8, md: 16 } : 0,
        left: isLandingPage ? '50%' : 0,
        transform: isLandingPage ? 'translateX(-50%)' : 'none',
        width: isLandingPage ? '95%' : '100%',
        maxWidth: isLandingPage ? '1400px' : 'none',
        zIndex: 1100,
        backgroundColor: isLandingPage ? 'transparent' : 'white',
        borderBottom: isLandingPage ? 'none' : '1px solid rgba(0, 0, 0, 0.1)',
        marginBottom: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: { xs: '56px', sm: '64px' },
          px: { xs: 2, sm: 3 },
          borderRadius: isLandingPage ? '16px' : 0,
          backdropFilter: isLandingPage ? 'blur(12px)' : 'none',
          backgroundColor: isLandingPage
            ? scrollTrigger
              ? 'rgba(195, 227, 211, 0.85)'
              : 'rgba(30, 20, 12, 0.2)'
            : 'white',
          boxShadow:
            isLandingPage && scrollTrigger
              ? '0 4px 20px rgba(195, 227, 211, 0.4)'
              : 'none',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <NavLink to="/" className="flex items-center">
          {isLandingPage && !scrollTrigger ? (
            <StrikeTutorLogoWhite
              sx={{ fontSize: { xs: '7.5rem', sm: '10rem' } }}
            />
          ) : (
            <StrikeTutorLogoBlack
              sx={{ fontSize: { xs: '7.5rem', sm: '10rem' } }}
            />
          )}
        </NavLink>

        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}
        >
          {isLoadingAccount ? (
            <Skeleton variant="text" width={60} height={32} />
          ) : account ? (
            <Button
              onClick={handleAvatarClick}
              disableRipple
              sx={{
                borderRadius: '8px',
                padding: '6px 8px',
                transition: 'all 0.2s',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: isLandingPage
                    ? 'rgba(255, 255, 255, 0.2)'
                    : 'rgba(30, 20, 12, 0.1)',
                },
                ...(isDropdownOpen && {
                  backgroundColor: isLandingPage
                    ? 'rgba(255, 255, 255, 0.2)'
                    : 'rgba(30, 20, 12, 0.1)',
                }),
              }}
            >
              <Avatar
                src={account.profilePictureUrl || undefined}
                alt="Account"
                sx={{
                  width: { xs: 32, sm: 36 },
                  height: { xs: 32, sm: 36 },
                  marginRight: 1,
                }}
              />
              <ArrowDropDown
                sx={{
                  color: isLandingPage ? 'white' : '#1E140C',
                  transition: 'transform 0.2s',
                  transform: isDropdownOpen ? 'rotate(180deg)' : 'none',
                }}
              />
            </Button>
          ) : isMobile ? (
            <IconButton
              onClick={() => setMobileMenuOpen(true)}
              sx={{
                color: isLandingPage ? 'white' : '#1E140C',
                backgroundColor: isLandingPage
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(30, 20, 12, 0.05)',
                '&:hover': {
                  backgroundColor: isLandingPage
                    ? 'rgba(255, 255, 255, 0.2)'
                    : 'rgba(30, 20, 12, 0.1)',
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : isLandingPage ? (
            <>
              <Button
                onClick={() => navigate('/signup')}
                sx={{
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  fontWeight: 500,
                  color: 'white',
                  borderRadius: '8px',
                  px: { xs: 1.5, sm: 2 },
                  py: 0.75,
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                  backgroundColor: '#1E140C',
                  '&:hover': {
                    backgroundColor: 'rgba(30, 20, 12, 0.85)',
                  },
                }}
              >
                Sign up
              </Button>
              <Button
                onClick={() => navigate('/login')}
                sx={{
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  fontWeight: 500,
                  color: '#1E140C',
                  borderRadius: '8px',
                  px: { xs: 1.5, sm: 2 },
                  py: 0.75,
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                  },
                }}
              >
                Log in
              </Button>
            </>
          ) : (
            <>
              <CustomButton
                url="/signup"
                text="Sign up"
                isLandingPage={false}
              />
              <CustomButton url="/login" text="Log in" isLandingPage={false} />
            </>
          )}

          <Menu
            open={isDropdownOpen}
            anchorEl={anchorEl}
            onClose={handleDropdownClose}
            onClick={handleDropdownClose}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '12px',
                minWidth: '220px',
                marginTop: '8px',
                backdropFilter: 'blur(8px)',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                border: '1px solid rgba(0, 0, 0, 0.08)',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            disablePortal
          >
            {account?.trainer && (
              <MenuItem
                onClick={() => {
                  handleDropdownClose()
                  setTimeout(() => {
                    navigate(`/trainer/${account.trainer?.trainerUuid}/profile`)
                  }, 0)
                }}
                sx={{
                  borderRadius: '8px',
                  margin: '4px',
                  padding: '10px 16px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <PhotoCameraFrontOutlined sx={{ mr: 2, color: '#666' }} />
                <Typography>Profile</Typography>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleDropdownClose()
                setTimeout(() => {
                  navigate('/account')
                }, 0)
              }}
              sx={{
                borderRadius: '8px',
                margin: '4px',
                padding: '10px 16px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <AccountCircleOutlined sx={{ mr: 2, color: '#666' }} />
              <Typography>Account</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDropdownClose()
                setTimeout(() => {
                  doLogout()
                }, 0)
              }}
              sx={{
                borderRadius: '8px',
                margin: '4px',
                padding: '10px 16px',
                color: '#be4142',
                '&:hover': {
                  backgroundColor: 'rgba(190, 65, 66, 0.08)',
                },
              }}
            >
              <LogoutOutlined sx={{ mr: 2 }} />
              <Typography fontWeight={500}>Logout</Typography>
            </MenuItem>
          </Menu>

          <Drawer
            anchor="right"
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
            sx={{
              '& .MuiDrawer-paper': {
                width: '100%',
                maxWidth: '300px',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                backdropFilter: 'blur(8px)',
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <IconButton onClick={() => setMobileMenuOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <List>
                <ListItem
                  component="div"
                  onClick={() => {
                    navigate('/login')
                    setMobileMenuOpen(false)
                  }}
                  sx={{ borderRadius: '8px', mb: 1 }}
                >
                  <ListItemIcon>
                    <Login sx={{ color: '#666' }} />
                  </ListItemIcon>
                  <ListItemText primary="Login" />
                </ListItem>
                <ListItem
                  component="div"
                  onClick={() => {
                    navigate('/signup')
                    setMobileMenuOpen(false)
                  }}
                  sx={{ borderRadius: '8px' }}
                >
                  <ListItemIcon>
                    <PersonAddAlt1 sx={{ color: '#666' }} />
                  </ListItemIcon>
                  <ListItemText primary="Sign Up" />
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </Box>
  )
}

export default Navbar

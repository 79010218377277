import { AccountCircle, Email, Verified } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Rating,
  Skeleton,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TrainerVerificationStatus } from '../models/Trainer'
import { EmailVerificationStatus } from '../models/User'
import { useAccount } from './auth/AuthProvider'
import { useUpcomingTrainerBookings } from './queries/UseBookingHook'
import LoadingPage from './shared/LoadingPage'
import UpcomingBookings from './UpcomingBookings'

// Initialize the plugin
dayjs.extend(isSameOrAfter)

// Add this helper function at the top of the file, after imports
const groupLessonsByDate = (lessons: any[]) => {
  const grouped = lessons.reduce((acc, lesson) => {
    const date = dayjs(lesson.startTime).format('YYYY-MM-DD')
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(lesson)
    return acc
  }, {})

  // Sort lessons within each date
  Object.keys(grouped).forEach((date) => {
    grouped[date].sort(
      (a: any, b: any) =>
        dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf()
    )
  })

  return grouped
}

// Add these helper functions after the groupLessonsByDate function
const findNearestDateWithLessons = (
  selectedDate: dayjs.Dayjs,
  groupedLessons: Record<string, any[]>
): string | null => {
  if (Object.keys(groupedLessons).length === 0) return null

  const dates = Object.keys(groupedLessons).sort()
  const selectedDateStr = selectedDate.format('YYYY-MM-DD')

  // If selected date has lessons, return it
  if (groupedLessons[selectedDateStr]) return selectedDateStr

  // Find the nearest date
  let nearestDate = dates[0]
  let minDiff = Math.abs(dayjs(dates[0]).diff(selectedDate, 'day'))

  dates.forEach((date) => {
    const diff = Math.abs(dayjs(date).diff(selectedDate, 'day'))
    if (diff < minDiff) {
      minDiff = diff
      nearestDate = date
    }
  })

  return nearestDate
}

const TrainerHome = () => {
  const navigate = useNavigate()

  // Fetch the account information from the AuthProvider
  const { account, isLoadingAccount: isLoadingAccount, user } = useAccount()
  const { data: upcomingBookings, isLoading: isLoadingBookings } =
    useUpcomingTrainerBookings(account?.trainer?.trainerUuid || '')

  const [selectedDate, setSelectedDate] = useState(dayjs())

  const renderDayWithDot = (props: PickersDayProps<dayjs.Dayjs>) => {
    const { day, ...other } = props
    const hasBooking = upcomingBookings?.some((booking) =>
      dayjs(booking.startDatetime).isSame(day, 'day')
    )

    return (
      // hacky way to color the day if there is a booking on that day
      // refer to https://mui.com/x/react-date-pickers/date-calendar/#dynamic-data
      <PickersDay
        {...other}
        day={day}
        sx={{
          ...other.sx,
          ...(hasBooking && {
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'rgba(255, 0, 0, 0.2)',
            },
            '&.Mui-selected': {
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            },
          }),
        }}
      />
    )
  }

  const scrollableBoxRef = useRef<HTMLDivElement>(null)

  if (isLoadingAccount) {
    return <LoadingPage />
  }

  const isProfileIncomplete =
    account?.trainer?.verificationStatus ===
    TrainerVerificationStatus.PROFILE_INCOMPLETE

  const isEmailVerified =
    user?.emailVerificationStatus === EmailVerificationStatus.PENDING

  const isTrainerVerificationPending =
    account?.trainer?.verificationStatus === TrainerVerificationStatus.PENDING

  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '80%',
        margin: '0 auto',
        width: '1100px',
        '@media (max-width: 600px)': {
          maxWidth: '100%',
          padding: '16px',
        },
      }}
    >
      {/* Banner for completing profile */}
      {isProfileIncomplete && (
        <Alert
          severity="warning"
          icon={<AccountCircle fontSize="inherit" />}
          sx={{
            position: 'sticky',
            top: 0,
            width: '100%',
            mb: 2,
            zIndex: 1000,
            '& .MuiAlert-icon': {
              color: '#ed6c02',
            },
            borderRadius: '12px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
          action={
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate('/complete-profile')}
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#ed6c02',
                '&:hover': {
                  backgroundColor: '#c55a00',
                },
                textTransform: 'none',
                borderRadius: '8px',
              }}
            >
              Complete Profile
            </Button>
          }
        >
          <Typography variant="body1" fontWeight={500}>
            Please complete your profile to enable all features
          </Typography>
        </Alert>
      )}

      {/* Banner for pending email verification */}
      {isEmailVerified && (
        <Alert
          severity="info"
          icon={<Email fontSize="inherit" />}
          sx={{
            position: 'sticky',
            top: 0,
            width: '100%',
            mb: 2,
            zIndex: 1000,
            '& .MuiAlert-icon': {
              color: '#0288d1',
            },
            borderRadius: '12px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#e3f2fd',
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            Please verify your email to unlock all features. Check your inbox
            for the verification link.
          </Typography>
        </Alert>
      )}

      {/* Banner for pending trainer verification */}
      {isTrainerVerificationPending && (
        <Alert
          severity="success"
          icon={<Verified fontSize="inherit" />}
          sx={{
            position: 'sticky',
            top: 0,
            width: '100%',
            mb: 2,
            zIndex: 1000,
            '& .MuiAlert-icon': {
              color: '#2e7d32',
            },
            borderRadius: '12px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#edf7ed',
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            Your trainer profile is pending approval from StrikeTutor. We'll
            notify you once approved!
          </Typography>
        </Alert>
      )}

      {/* Main content with blur effect if verification is false */}
      <Box
        display="flex"
        flexDirection="column"
        gap="28px"
        sx={{
          filter: isProfileIncomplete ? 'blur(4px)' : 'none',
          pointerEvents: isProfileIncomplete ? 'none' : 'auto', // Disable interactions when blurred
          opacity: isProfileIncomplete ? 0.6 : 1,
          transition: 'opacity 0.3s ease',
        }}
      >
        {/* Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" fontWeight="bold">
            Hi {account?.firstName}, welcome back!
          </Typography>
          <Box display="flex" alignItems="center"></Box>
        </Box>

        {/* Calendar and Information Section */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr', // Single column on mobile
              md: '380px 1fr', // Two columns on desktop (calendar width + flex)
              lg: '380px 1fr', // Two columns on desktop (calendar width + flex)
            },
            gap: 2,
          }}
        >
          <Card>
            <CardContent>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  sx={{ height: '300px' }}
                  value={selectedDate}
                  views={['day']}
                  onChange={(newDate) => {
                    if (!newDate) return
                    setSelectedDate(newDate)

                    const groupedLessons = groupLessonsByDate(
                      upcomingBookings || []
                    )
                    const targetDate = findNearestDateWithLessons(
                      newDate,
                      groupedLessons
                    )

                    if (targetDate) {
                      const element = document.getElementById(
                        `lesson-box-${targetDate}-0`
                      )
                      if (element && scrollableBoxRef.current) {
                        const container = scrollableBoxRef.current
                        const headerHeight = 48 // Height of the sticky date header
                        const elementPosition =
                          element.offsetTop - container.offsetTop - headerHeight

                        container.scrollTo({
                          top: elementPosition,
                          behavior: 'smooth',
                        })
                      }
                    }
                  }}
                  slots={{
                    day: renderDayWithDot,
                  }}
                />
              </LocalizationProvider>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#000000',
                  '&:hover': {
                    backgroundColor: '#333333',
                  },
                  height: '56px',
                  borderRadius: '16px',
                }}
                onClick={() => navigate(`/trainer/availability`)}
                disabled={isProfileIncomplete}
              >
                <Typography
                  variant="button"
                  sx={{ textTransform: 'none', fontSize: '16px' }}
                >
                  Edit availability
                </Typography>
              </Button>
            </CardContent>
          </Card>
          <Card
            ref={scrollableBoxRef}
            sx={{
              overflowY: 'auto',
              width: '100%',
              height: '100%',
              display: 'flex',
            }}
          >
            <CardContent sx={{ flex: 1 }}>
              {isLoadingBookings ? (
                <Box sx={{ mb: 2, p: 2, borderRadius: '12px' }}>
                  <Skeleton variant="text" width="60%" height={28} />
                  <Skeleton variant="text" width="40%" height={20} />
                  <Skeleton variant="text" width="35%" height={20} />
                </Box>
              ) : (
                <UpcomingBookings upcomingBookings={upcomingBookings} />
              )}
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6" fontWeight="bold">
            What learners say about you
          </Typography>
          <Card
            sx={{
              p: 2,
              minHeight: '120px',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <Rating disabled defaultValue={5} sx={{ color: '#9e9e9e' }} />
              <Typography variant="body2" color="#9e9e9e">
                No reviews yet
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export default TrainerHome

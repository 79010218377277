import { useQuery } from '@tanstack/react-query'
import {
  getTrainerAvailableTrainings,
  getTrainerProfile,
} from '../../clients/TrainerClient'
import { TrainerProfile } from '../../models/TrainerProfile'
import { WEEKDAYS } from '../shared/constants'

export const useTrainerProfile = (trainerUuid: string) => {
  return useQuery<TrainerProfile, Error>({
    queryKey: ['trainerProfile', trainerUuid],
    queryFn: async () => {
      const response = await getTrainerProfile(trainerUuid)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()

      return new TrainerProfile({
        ...data.trainerProfile,
      })
    },
    enabled: !!trainerUuid,
  })
}

export const useOpenTrainings = (trainerUuid: string) => {
  return useQuery({
    queryKey: ['trainerOpenTrainings', trainerUuid],
    queryFn: async () => {
      const response = await getTrainerAvailableTrainings(trainerUuid)
      if (!response.ok) {
        throw new Error(
          `Failed to fetch open trainings: ${response.statusText}`
        )
      }

      const data = await response.json()
      const openTrainings = data.availableTrainings || []

      const days = openTrainings.map((training: { startDatetime: string }) => {
        const date = new Date(training.startDatetime)
        return WEEKDAYS[date.getDay()]
      })

      return Array.from(new Set(days))
    },
    enabled: !!trainerUuid,
  })
}

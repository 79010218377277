import { AccountCircleOutlined } from '@mui/icons-material'
import { Box, List, ListItemText, Typography } from '@mui/material'
import { useState } from 'react'
import { TabItem, TabItemButton } from '../accountManagement/StyledComponents'
import AdminTrainers from './AdminTrainers'
import Users from './AdminUsers'

enum AdminPages {
  ADMIN_PANEL = 'admin_panel',
  USERS = 'users',
  TRAINERS = 'trainers',
  LEARNERS = 'learners',
}

const AdminPage = () => {
  const [currentPage, setCurrentPage] = useState(AdminPages.ADMIN_PANEL)

  return (
    <Box display="flex">
      {/* Sidebar */}
      <List sx={{ pt: 4, px: 2 }}>
        <TabItem>
          <TabItemButton
            onClick={() => setCurrentPage(AdminPages.ADMIN_PANEL)}
            sx={{
              position: 'relative',
              zIndex: 10,
            }}
          >
            <AccountCircleOutlined
              sx={{ marginRight: 2, color: 'text.secondary', fontSize: 22 }}
            />
            <ListItemText
              primary={
                <Typography variant="body1" fontWeight={600}>
                  Admin Panel
                </Typography>
              }
            />
          </TabItemButton>
        </TabItem>
        <TabItem>
          <TabItemButton onClick={() => setCurrentPage(AdminPages.USERS)}>
            <AccountCircleOutlined
              sx={{ marginRight: 2, color: 'text.secondary', fontSize: 22 }}
            />
            <ListItemText
              primary={
                <Typography variant="body1" fontWeight={600}>
                  Users
                </Typography>
              }
            />
          </TabItemButton>
        </TabItem>
        <TabItem>
          <TabItemButton onClick={() => setCurrentPage(AdminPages.TRAINERS)}>
            <AccountCircleOutlined
              sx={{ marginRight: 2, color: 'text.secondary', fontSize: 22 }}
            />
            <ListItemText
              primary={
                <Typography variant="body1" fontWeight={600}>
                  Trainer
                </Typography>
              }
            />
          </TabItemButton>
        </TabItem>
      </List>

      {/* Main Content */}
      <Box
        flex={1}
        ml={16}
        p={4}
        mt={4}
        sx={{
          marginLeft: '16rem',
          marginTop: '4rem',
        }}
      >
        {/* Conditionally render AdminPanel, Users, or Trainers component */}
        {currentPage === AdminPages.ADMIN_PANEL && (
          <Typography variant="body1">Welcome to the Admin Panel</Typography>
        )}
        {currentPage === AdminPages.USERS && <Users />}
        {currentPage === AdminPages.TRAINERS && <AdminTrainers />}
      </Box>
    </Box>
  )
}

export default AdminPage

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const stripeElementsStyles = {
  variables: {
    colorPrimary: '#151922',
    colorBackground: '#ffffff',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: 'Bricolage Grotesque, sans-serif',
    spacingUnit: '6px',
    borderRadius: '4px',
  },
}

export const BorderedBox = styled(Box)(() => ({
  padding: '24px',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  backgroundColor: '#ffffff',
}))

export const TabItem = styled(ListItem)(() => ({
  marginBottom: 2,
  padding: 0,
}))

export const TabItemButton = styled(ListItemButton)(() => ({
  borderRadius: '8px',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  transition: 'all 0.2s ease',
  '&.Mui-selected': {
    backgroundColor: '#EBEBEB',
    color: '#1E140C',
    '&:hover': {
      backgroundColor: '#DFDFDF',
    },
    '& .MuiSvgIcon-root': {
      color: '#1E140C',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(30, 20, 12, 0.04)',
  },
}))

export const FieldLabel = styled(Typography)(() => ({
  color: '#666',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  letterSpacing: '0.5px',
}))

export const EditButton = styled(Button)(() => ({
  textTransform: 'none',
  border: '1px solid #2c2c2c',
  color: '#2c2c2c',
  borderRadius: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
  transition: 'all 0.2s',
  '&:hover': {
    border: '1px solid #2c2c2c',
    backgroundColor: 'rgba(44, 44, 44, 0.04)',
    transform: 'translateY(-1px)',
  },
}))

export const SaveButton = styled(LoadingButton)(() => ({
  textTransform: 'none',
  borderRadius: '4px',
  paddingLeft: '24px',
  paddingRight: '24px',
  backgroundColor: '#2c2c2c',
  '&:hover': {
    backgroundColor: '#1a1a1a',
  },
}))

export const CancelButton = styled(Button)(() => ({
  textTransform: 'none',
  borderRadius: '4px',
  paddingLeft: '24px',
  paddingRight: '24px',
  border: '2px solid #2c2c2c',
  color: '#2c2c2c',
  '&:hover': {
    border: '2px solid #2c2c2c',
    backgroundColor: 'rgba(44, 44, 44, 0.04)',
  },
}))

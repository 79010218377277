import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import InstagramStyledIcon from './InstagramStyledIcon'

const FooterLink = styled(Link)({
  color: '#606060',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const Footer = () => {
  const location = useLocation()

  const hideFooterPaths = ['/signup', '/complete-profile']

  const shouldHideFooter = hideFooterPaths.includes(location.pathname)

  if (shouldHideFooter) {
    return null
  }

  return (
    <Box
      component="footer"
      py={1.5}
      borderTop="1px solid"
      borderColor="grey.200"
    >
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="row" justifyContent="space-around">
          <Box width="25%">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textPrimary"
            >
              About
            </Typography>
            <List disablePadding>
              <ListItem disableGutters>
                <FooterLink to="/who-we-are">Who are we?</FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/terms-and-conditions">
                  Terms & conditions
                </FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/privacy-policy">Privacy policy</FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/cookies-policy">Cookies policy</FooterLink>
              </ListItem>
            </List>
          </Box>

          <Box width="25%">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textPrimary"
            >
              Resources
            </Typography>
            <List disablePadding>
              <ListItem disableGutters>
                <FooterLink to="/help-center">Help center</FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/contact-us">Contact us</FooterLink>
              </ListItem>
            </List>
          </Box>

          <Box width="25%">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textPrimary"
            >
              Follow us
            </Typography>
            <Box display="flex" flexDirection="row">
              <IconButton
                href="https://www.instagram.com/striketutor"
                target="_blank"
              >
                <InstagramStyledIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          m={2}
          fontSize="0.75rem"
        >
          © 2025 StrikeTutor. All rights reserved.
        </Typography>
      </Container>
    </Box>
  )
}

export default Footer

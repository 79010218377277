import {
  QueryObserverResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { createContext, useContext, useMemo } from 'react'
import { getAccount } from '../../clients/AccountClient'
import { loginUser, logoutUser } from '../../clients/AuthClient'
import { getUser } from '../../clients/UserClient'
import Account from '../../models/Account'
import User from '../../models/User'

interface AuthContextProps {
  account: Account | null
  isLoadingAccount: boolean
  refetchAccount: () => Promise<QueryObserverResult<Account, Error>>

  user: User | null
  isLoadingUser: boolean
  refetchUser: () => Promise<QueryObserverResult<User, Error>>

  doLogin: (email: string, password: string) => Promise<Response>
  doLogout: () => void
}

const AuthContext = createContext({} as AuthContextProps)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient()

  const {
    data: account,
    isLoading: isLoadingAccount,
    refetch: refetchAccount,
  } = useQuery({
    queryKey: ['account'],
    queryFn: async () => {
      const response = await getAccount()
      const data = await response.json()

      if (!response.ok) {
        return null
      }

      return data.account || null
    },
  })

  const {
    data: user,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const response = await getUser()
      const data = await response.json()

      if (!response.ok) {
        return null
      }

      return data.user || null
    },
  })

  const loginMutation = useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string
      password: string
    }) => {
      const response = await loginUser(email, password)
      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Account not yet verified')
        } else if (response.status === 400) {
          throw new Error('Invalid email or password.')
        } else {
          throw new Error('An unknown error occurred. Please try again.')
        }
      }
      return response
    },
    onSuccess: async () => {
      await refetchUser()
      await refetchAccount()

      const params = new URLSearchParams(window.location.search)
      const redirectUrl = params.get('redirectUrl') || '/'
      window.location.href = redirectUrl
    },
  })

  const logoutMutation = useMutation({
    mutationFn: async () => {
      const response = await logoutUser()
      if (!response.ok) {
        throw new Error('Failed to log out')
      }
      return response
    },
    onSuccess: async () => {
      queryClient.setQueryData(['account'], undefined)
      queryClient.setQueryData(['user'], undefined)

      window.location.href = '/'
    },
  })

  const doLogin = async (email: string, password: string) => {
    return loginMutation.mutateAsync({ email, password })
  }

  const doLogout = () => {
    return logoutMutation.mutate()
  }

  const contextValue = useMemo(
    () => ({
      account,
      user,
      doLogin,
      doLogout,
      isLoadingAccount,
      refetchAccount,
      isLoadingUser,
      refetchUser,
    }),
    [
      account,
      doLogin,
      doLogout,
      isLoadingAccount,
      refetchAccount,
      user,
      isLoadingUser,
      refetchUser,
    ]
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAccount = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAccount must be used within an AuthProvider')
  }

  return context
}

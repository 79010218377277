// Instagram.tsx
import InstagramIcon from '@mui/icons-material/Instagram'
import { styled } from '@mui/material'

const InstagramStyledIcon = styled(InstagramIcon)({
  fontSize: '24px',
  background: 'linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)',
  borderRadius: '50%',
  padding: '4px',
  color: 'white',
})

export default InstagramStyledIcon

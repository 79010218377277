import { Box } from '@mui/material'
import Footer from './Footer'
import Navbar from './Navbar'

const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        maxWidth="100vw"
        overflow="hidden"
      >
        <Navbar />
        {children}
      </Box>
      <Footer />
    </>
  )
}

export default Layout

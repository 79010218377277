import Account from './Account'

enum TrainerVerificationStatus {
  PENDING = 'pending',
  VERIFIED = 'verified',
  DENIED = 'denied',
  PROFILE_INCOMPLETE = 'profile_incomplete',
}

interface Trainer {
  trainerUuid: string
  accountUuid: string
  defaultBowlingCenterUuid: string
  certifications: string[] | null
  about: string | null
  hourlyRate: number | null
  bowlingStyles: string[] | null
  verificationStatus: TrainerVerificationStatus
  stripeAccountId: string | null
}

interface BookingTrainer extends Trainer {
  account: Account
}
export { TrainerVerificationStatus }
export type { BookingTrainer, Trainer }

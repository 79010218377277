import { PlacePicker as TPlacePicker } from '@googlemaps/extended-component-library/place_picker.js'
import { PlacePicker } from '@googlemaps/extended-component-library/react'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CloseIcon from '@mui/icons-material/Close'
import DehazeIcon from '@mui/icons-material/Dehaze'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import VerifiedIcon from '@mui/icons-material/Verified'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  Popover,
  Select,
  Skeleton,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { APIProvider } from '@vis.gl/react-google-maps'
import { Position } from 'geojson'
import { ElementType, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useGetTrainersByLocation } from '../components/queries/UseTrainerSearchHook'
import { TrainerProfile } from '../models/TrainerProfile'
import { useAccount } from './auth/AuthProvider'
import { usePreviousAndUpcomingLearnerBookings } from './queries/UseBookingHook'
import { useGetPopularTrainers } from './queries/UseTrainersHook'
import UpcomingBookings from './UpcomingBookings'

const LearnerHome = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  // Get screen size breakpoints
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))

  const [localSearchCoordinates, setLocalSearchCoordinates] = useState<
    [number, number]
  >([Number(searchParams.get('lat')), Number(searchParams.get('lng'))])
  const [searchResults, setSearchResults] = useState<TrainerProfile[]>([])
  const { account, isLoadingAccount } = useAccount()
  const { data: bookings, isLoading: isLoadingBookings } =
    usePreviousAndUpcomingLearnerBookings(account?.learner?.learnerUuid || '')
  const { data: locationSearchResults, isLoading: isLoadingLocationSearch } =
    useGetTrainersByLocation(localSearchCoordinates as Position)
  const { data: popularTrainers } = useGetPopularTrainers()
  const [isLoadingSearch, setIsLoadingSearch] = useState(
    isLoadingLocationSearch
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(9) // Default to 9 items
  const [selectedTechnique, setSelectedTechnique] = useState<string | null>(
    null
  )
  const [selectedCertification, setSelectedCertification] = useState<
    string | null
  >(null)
  const [selectedDistance, setSelectedDistance] = useState<number[]>([0, 100])
  const [selectedPrice, setSelectedPrice] = useState<number[]>([0, 150])
  const [sortOption, setSortOption] = useState<string>('recommended')
  const [anchorElDistance, setAnchorElDistance] = useState<null | HTMLElement>(
    null
  )
  const [anchorElPrice, setAnchorElPrice] = useState<null | HTMLElement>(null)
  const [isFilterModalOpen, setFilterModalOpen] = useState(false)
  const placePickerRef = useRef<TPlacePicker>(null)

  const isDistanceOpen = Boolean(anchorElDistance)
  const isPriceOpen = Boolean(anchorElPrice)

  // Update items per page based on screen size
  useEffect(() => {
    if (isSmallScreen) {
      setItemsPerPage(4) // 2x2 grid
    } else if (isMediumScreen) {
      setItemsPerPage(6) // 2x3 grid
    } else {
      setItemsPerPage(9) // 3x3 grid
    }
  }, [isSmallScreen, isMediumScreen, isLargeScreen])

  useEffect(() => {
    const fetchInitialSearchResults = async () => {
      setSearchResults([])

      if (locationSearchResults) {
        setSearchResults(locationSearchResults)
      } else if (popularTrainers) {
        setSearchResults(popularTrainers)
      }
    }

    setIsLoadingSearch(true)
    fetchInitialSearchResults()
    setIsLoadingSearch(false)
  }, [account, locationSearchResults, popularTrainers])

  // Determine font size based on device
  const getFontSize = () =>
    isMobile ? '0.5rem' : isTablet ? '0.625rem' : '0.8rem'

  // Navigate to trainer profile page
  const handleTrainerClick = (trainerUuid: string) => {
    navigate(`/trainer/${trainerUuid}/profile`)
  }

  // Handle Distance Popover
  const handleDistanceClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDistance(event.currentTarget)
  }

  const handleDistanceClose = () => {
    setAnchorElDistance(null)
  }

  // Handle Price Popover
  const handlePriceClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPrice(event.currentTarget)
  }

  const handlePriceClose = () => {
    setAnchorElPrice(null)
  }

  // Update filtering logic to work with search results
  const filteredTrainers = searchResults
    ? searchResults.filter((trainer) => {
        const priceInRange =
          !selectedPrice ||
          (Number(trainer.hourlyRate) >= selectedPrice[0] &&
            Number(trainer.hourlyRate) <= selectedPrice[1])

        const hasSelectedStyle =
          !selectedTechnique ||
          (trainer.bowlingStyles &&
            trainer.bowlingStyles.includes(selectedTechnique))

        const hasCertification =
          !selectedCertification ||
          (trainer.certifications &&
            trainer.certifications.includes(selectedCertification))

        // Note: Distance filtering would need some work.. not sure how to filter it here.

        return priceInRange && hasSelectedStyle && hasCertification
      })
    : []

  // Update sorting logic
  const sortedTrainers = [...filteredTrainers].sort((a, b) => {
    switch (sortOption) {
      case 'priceLowHigh':
        return Number(a.hourlyRate) - Number(b.hourlyRate)
      case 'priceHighLow':
        return Number(b.hourlyRate) - Number(a.hourlyRate)
      default:
        return 0
    }
  })

  // Update pagination to use sorted results
  const paginatedResults = sortedTrainers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const totalPages = Math.ceil(sortedTrainers.length / itemsPerPage)

  // Update filter options based on available data
  const availableBowlingStyles = Array.from(
    new Set(
      searchResults?.flatMap((trainer) => trainer.bowlingStyles || []) || []
    )
  )

  const availableCertifications = Array.from(
    new Set(
      searchResults?.flatMap((trainer) => trainer.certifications || []) || []
    )
  )

  // Calculate grid columns based on screen size
  const gridColumns = isSmallScreen ? 1 : isMediumScreen ? 2 : 3

  const renderFilterDropdown = () => (
    <Box p={3}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Sort & Filter
        </Typography>
        <IconButton onClick={() => setFilterModalOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormControl fullWidth variant="outlined" margin="dense">
        <InputLabel>Sort by</InputLabel>
        <Select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          label="Sort by"
        >
          <MenuItem value="recommended">Recommended</MenuItem>
          <MenuItem value="priceLowHigh">Price low to high</MenuItem>
          <MenuItem value="priceHighLow">Price high to low</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
        Filter by
      </Typography>
      {renderFilter(
        'Techniques',
        selectedTechnique,
        setSelectedTechnique,
        availableBowlingStyles
      )}
      {renderFilter(
        'Certification',
        selectedCertification,
        setSelectedCertification,
        availableCertifications
      )}
      <Box mt={2}>
        <Typography>Distance (miles)</Typography>
        <Slider
          value={selectedDistance}
          onChange={(_, newValue) => setSelectedDistance(newValue as number[])}
          valueLabelDisplay="auto"
          min={0}
          max={100}
        />
      </Box>
      <Box mt={2}>
        <Typography>Price ($)</Typography>
        <Slider
          value={selectedPrice}
          onChange={(_, newValue) => setSelectedPrice(newValue as number[])}
          valueLabelDisplay="auto"
          min={0}
          max={150}
        />
      </Box>
      <Button
        onClick={() => setFilterModalOpen(false)}
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3 }}
      >
        Done
      </Button>
    </Box>
  )

  return (
    <Box
      p={2}
      gap={4}
      mx={isMobile ? 0 : 12}
      sx={{
        '& .MuiFormControl-root': {
          minWidth: isMobile ? 100 : 130,
          fontSize: getFontSize(),
        },
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
      width="100%"
      maxWidth="1100px"
    >
      {/* Mobile Filter Button */}
      {isMobile && (
        <Button
          variant="outlined"
          onClick={() => setFilterModalOpen(true)}
          startIcon={<DehazeIcon />}
          sx={{ my: 2, borderRadius: 8 }}
        >
          Sort & Filter
        </Button>
      )}
      {/* Modal for Sort & Filter in Mobile View */}
      <Modal
        open={isFilterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '90%',
            maxWidth: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 3,
            position: 'relative',
          }}
        >
          {/* Filter Dropdown Content */}
          {renderFilterDropdown()}
        </Box>
      </Modal>
      {/* Welcome Section - Only show for learners */}
      {account?.learner && (
        <>
          {location.state?.bookingUuid ? (
            <Alert severity="success" sx={{ mb: 2, fontSize: '1rem' }}>
              Lesson booked! You can check the details in your upcoming lessons
            </Alert>
          ) : isLoadingAccount ? (
            <Skeleton variant="text" width={300} height={60} sx={{ mb: 3 }} />
          ) : (
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
              Hi {account?.firstName}, welcome back!
            </Typography>
          )}

          {/* Lessons Section */}
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              flexDirection: { xs: 'column', md: 'row' },
              height: { xs: 'auto', md: '360px' },
            }}
          >
            {/* Recent Lessons */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                maxWidth: { xs: '100%', md: '400px' },
              }}
            >
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                Recent lessons
              </Typography>
              {isLoadingBookings ? (
                <Box
                  sx={{
                    bgcolor: 'white',
                    borderRadius: 2,
                    border: 1,
                    borderColor: 'grey.200',
                    p: 3,
                  }}
                >
                  <Skeleton variant="text" width="60%" height={30} />
                  <Skeleton variant="text" width="40%" height={24} />
                  <Skeleton variant="rectangular" height={36} sx={{ mt: 2 }} />
                </Box>
              ) : (
                <Card
                  sx={{
                    bgcolor: 'white',
                    height: '100%',
                  }}
                >
                  <CardContent sx={{ height: '100%' }}>
                    {bookings?.previousBooking ? (
                      <Box>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {bookings.previousBooking.trainer.account.firstName}{' '}
                          {bookings.previousBooking.trainer.account.lastName} @{' '}
                          {bookings.previousBooking.bowlingCenter.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {new Date(
                            bookings.previousBooking.startDatetime
                          ).toLocaleDateString()}{' '}
                          |{' '}
                          {new Date(
                            bookings.previousBooking.startDatetime
                          ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                          height: '100%',
                        }}
                      >
                        <CalendarTodayIcon
                          sx={{ color: 'grey.400', fontSize: 40 }}
                        />
                        <Typography sx={{ color: 'grey.500' }}>
                          No recent lessons
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              )}
            </Box>

            {/* Upcoming Lessons */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: '100%',
              }}
            >
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                Upcoming lessons
              </Typography>
              {isLoadingBookings ? (
                <Box
                  sx={{
                    bgcolor: 'white',
                    borderRadius: 2,
                    border: 1,
                    borderColor: 'grey.200',
                    p: 3,
                  }}
                >
                  <Skeleton variant="text" width="60%" height={30} />
                  <Skeleton variant="text" width="40%" height={24} />
                  <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                    <Skeleton variant="rectangular" width="50%" height={36} />
                    <Skeleton variant="rectangular" width="50%" height={36} />
                  </Box>
                </Box>
              ) : (
                <Card sx={{ flex: 1 }}>
                  <CardContent sx={{ height: '100%' }}>
                    <UpcomingBookings
                      upcomingBookings={bookings?.upcomingBookings || []}
                    />
                  </CardContent>
                </Card>
              )}
            </Box>
          </Box>
        </>
      )}

      {/* Search bar section*/}
      <Box
        sx={{
          mb: 4,
          gap: 2,
          width: { xs: '100%', sm: '60%' },
          maxWidth: '800px',
          mx: 'auto',
        }}
      >
        <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
          <PlacePicker
            className="w-full"
            ref={placePickerRef}
            country={['us']}
            type="bowling_alley"
            placeholder="Enter a bowling center in the US"
            onPlaceChange={() => {
              if (placePickerRef.current?.value?.location) {
                setLocalSearchCoordinates([
                  placePickerRef.current.value.location.lng(),
                  placePickerRef.current.value.location.lat(),
                ])
              } else {
                setLocalSearchCoordinates([0, 0])
              }
            }}
          />
        </APIProvider>
      </Box>

      {/* Filter Section */}
      {!isMobile && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
          mb={2}
        >
          <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
            <Typography variant="h6" fontWeight="bold" fontSize={getFontSize()}>
              Filter
            </Typography>
            {renderFilter(
              'Techniques',
              selectedTechnique,
              setSelectedTechnique,
              availableBowlingStyles
            )}
            {renderFilter(
              'Certification',
              selectedCertification,
              setSelectedCertification,
              availableCertifications
            )}
            {/* Distance Control */}
            <FormControl variant="outlined" size="small" sx={{ minWidth: 130 }}>
              <InputLabel
                sx={{
                  fontSize: '0.85rem',
                }}
              >
                Distance
              </InputLabel>
              <Select
                value={''}
                onClick={handleDistanceClick}
                input={<OutlinedInput label="Distance" />}
                readOnly
              >
                <MenuItem disabled>
                  {selectedDistance
                    ? `Up to ${selectedDistance[1]} mi.`
                    : 'Distance'}
                </MenuItem>
              </Select>
            </FormControl>
            <Popover
              open={isDistanceOpen}
              anchorEl={anchorElDistance}
              onClose={handleDistanceClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box p={2} sx={{ minWidth: '200px', maxWidth: '300px' }}>
                <Typography>Distance (miles)</Typography>
                <Slider
                  value={selectedDistance}
                  onChange={(_, newValue) =>
                    setSelectedDistance(newValue as number[])
                  }
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                />
              </Box>
            </Popover>
            {/* Price Control */}
            <FormControl variant="outlined" size="small" sx={{ minWidth: 130 }}>
              <InputLabel
                sx={{
                  fontSize: '0.85rem',
                }}
              >
                Price
              </InputLabel>
              <Select
                value={''}
                onClick={handlePriceClick}
                input={<OutlinedInput label="Price" />}
                readOnly
              >
                <MenuItem disabled>
                  {`$${selectedPrice[0]} - $${selectedPrice[1]}`}
                </MenuItem>
              </Select>
            </FormControl>
            <Popover
              open={isPriceOpen}
              anchorEl={anchorElPrice}
              onClose={handlePriceClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box p={2} sx={{ minWidth: '200px', maxWidth: '300px' }}>
                <Typography>Price ($)</Typography>
                <Slider
                  value={selectedPrice}
                  onChange={(_, newValue) =>
                    setSelectedPrice(newValue as number[])
                  }
                  valueLabelDisplay="auto"
                  min={0}
                  max={150}
                />
              </Box>
            </Popover>
          </Box>
          <FormControl variant="outlined" size="small">
            <InputLabel style={{ fontSize: getFontSize() }}>Sort by</InputLabel>
            <Select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              input={<OutlinedInput label="Sort by" />}
              sx={{ fontSize: getFontSize() }}
            >
              <MenuItem value="recommended">Recommended</MenuItem>
              <MenuItem value="priceLowHigh">Price low to high</MenuItem>
              <MenuItem value="priceHighLow">Price high to low</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Trainer Cards */}
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${gridColumns}, 1fr)`}
        gap={2}
        sx={{
          width: '100%',
        }}
      >
        {isLoadingSearch ? (
          [...Array(3)].map((_, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: 2,
                border: '1px solid #e0e0e0', // Light gray border
                bgcolor: '#fff',
                overflow: 'hidden',
              }}
            >
              <Skeleton variant="rectangular" height={200} />
              <Box p={2}>
                <Skeleton
                  variant="text"
                  width="60%"
                  height={32}
                  sx={{ mb: 1 }}
                />
                <Box display="flex" alignItems="center" mb={1} gap={1}>
                  <Skeleton variant="text" width={30} />
                  <Skeleton variant="circular" width={16} height={16} />
                  <Skeleton variant="text" width={40} />
                </Box>
                <Box display="flex" gap={1} mb={2}>
                  <Skeleton variant="rounded" width={80} height={24} />
                  <Skeleton variant="rounded" width={80} height={24} />
                </Box>
                <Skeleton variant="text" width="80%" sx={{ mb: 1 }} />
                <Skeleton variant="text" width="70%" sx={{ mb: 1 }} />
                <Skeleton variant="text" width="50%" />
              </Box>
            </Box>
          ))
        ) : paginatedResults.length > 0 ? (
          paginatedResults.map((trainer) => (
            <Box
              key={trainer.trainerUuid}
              onClick={() => handleTrainerClick(trainer.trainerUuid)}
              sx={{
                cursor: 'pointer',
                borderRadius: 2,
                border: '1px solid #e0e0e0', // Light gray border
                bgcolor: '#fff',
                overflow: 'hidden',
                transition: 'border-color 0.2s',
                '&:hover': {
                  borderColor: '#bdbdbd', // Darker gray on hover
                },
              }}
            >
              <Box
                component="img"
                src={trainer.profilePictureUrl}
                alt={trainer.firstName}
                sx={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
              <Box p={2} flexGrow={1}>
                <Typography variant="h6" noWrap>
                  {trainer.lastName}
                </Typography>
                <Box display="flex" alignItems="center" mb={1} gap={1}>
                  {/* <Typography variant="body1" fontWeight="bold">
                    {Number(trainer.averageRating).toFixed(1)}
                  </Typography> */}
                </Box>
                <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                  {trainer.bowlingStyles?.map((type: string) => (
                    <Chip
                      key={type}
                      label={type}
                      sx={{
                        fontSize: '0.875rem',
                        bgcolor:
                          type === 'Two-handed'
                            ? '#AFFFE7'
                            : type === 'Stroker'
                              ? '#E2ECF6'
                              : type === 'Cranker'
                                ? '#F6E2E2'
                                : '#E2F6F1',
                      }}
                    />
                  ))}
                </Box>
                <InfoRow
                  icon={LocationOnIcon}
                  text={trainer?.defaultBowlingCenter?.name || 'Bowling Center'}
                />
                <InfoRow
                  icon={VerifiedIcon}
                  // @ts-ignore
                  text={trainer.certifications}
                  iconColor="green"
                />
                <InfoRow
                  icon={LocalAtmIcon}
                  text={`$${trainer.hourlyRate} per session`}
                />
              </Box>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              minHeight: '200px',
              gridColumn: `span ${gridColumns}`,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No trainers found matching your filters
            </Typography>
          </Box>
        )}
      </Box>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Box
          display="flex"
          justifyContent="center"
          mt={3}
          sx={{
            '& .MuiPagination-ul': {
              flexWrap: 'nowrap',
            },
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => {
              setCurrentPage(page)
              // Scroll to top of results
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
            color="primary"
            size={isSmallScreen ? 'small' : 'medium'}
            siblingCount={isSmallScreen ? 0 : 1}
            boundaryCount={isSmallScreen ? 1 : 2}
          />
        </Box>
      )}
    </Box>
  )
}

// Custom InfoRow component with type annotations
const InfoRow = ({
  icon: Icon,
  text,
  iconColor,
}: {
  icon: ElementType
  text: string | string[]
  iconColor?: string
}) => (
  <Typography
    variant="body2"
    display="flex"
    alignItems="center"
    gap={0.5}
    mb={1}
    fontWeight={Icon === LocalAtmIcon ? 'bold' : 'normal'}
  >
    <Icon fontSize="small" sx={{ color: iconColor }} />
    {Array.isArray(text) ? text.join(', ') : text}
  </Typography>
)

// Helper function to render filter components
const renderFilter = (
  label: string,
  value: string | null,
  setValue: React.Dispatch<React.SetStateAction<string | null>>,
  options: string[],
  width: number = 145
) => (
  <FormControl variant="outlined" size="small" sx={{ width }}>
    <InputLabel sx={{ fontSize: '0.85rem' }}>{label}</InputLabel>
    <Select
      value={value || ''}
      onChange={(e) => setValue(e.target.value || null)}
      input={<OutlinedInput label={label} />}
    >
      <MenuItem value="">None</MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default LearnerHome

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Avatar, Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Booking from '../models/Booking'
import { bookingInXDays } from './shared/DayUtils'
import { getGoogleMapsLink } from './shared/MapUtility'

const groupLessonsByDate = (bookings: Booking[]) => {
  const grouped = bookings.reduce((acc: Record<string, Booking[]>, booking) => {
    const date = dayjs(booking.startDatetime).format('YYYY-MM-DD')
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(booking)

    return acc
  }, {})

  // Sort lessons within each date
  Object.keys(grouped).forEach((date) => {
    grouped[date].sort(
      (a: Booking, b: Booking) =>
        dayjs(a.startDatetime).valueOf() - dayjs(b.startDatetime).valueOf()
    )
  })

  return grouped
}

const UpcomingBookings = ({
  upcomingBookings,
}: {
  upcomingBookings?: Booking[]
}) => {
  const navigate = useNavigate()

  if (!upcomingBookings || upcomingBookings.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '8rem',
            justifyContent: 'center',
          }}
        >
          <CalendarTodayIcon sx={{ color: 'grey.400', fontSize: 40, mb: 1 }} />
          <Typography color="#9e9e9e">No upcoming lessons</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        maxHeight="360px"
        overflow="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: '#bdbdbd',
          },
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'thin',
          scrollbarColor: 'transparent transparent',
          '&:hover': {
            scrollbarColor: '#bdbdbd transparent',
          },
        }}
      >
        {Object.entries(groupLessonsByDate(upcomingBookings))
          .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
          .map(([date, bookings]) => (
            <Box key={date} sx={{ mb: 3, minWidth: '320px', width: '95%' }}>
              <Typography
                variant="h6"
                id={`date-header-${date}`}
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  p: 1,
                  zIndex: 1,
                  fontWeight: 'bold',
                }}
              >
                {dayjs(date).format('dddd, MMMM D')}
              </Typography>
              {bookings.map((booking, index) => (
                <Box
                  key={index}
                  id={`lesson-box-${date}-${index}`}
                  sx={{
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      mr: 2,
                      minWidth: '100px',
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {dayjs(booking.startDatetime).format('h:mm A')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {bookingInXDays(booking) === 0
                        ? 'today'
                        : bookingInXDays(booking) === 1
                          ? 'tomorrow'
                          : `${bookingInXDays(booking)} days left`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      p: 2,
                      border: '2px solid #e0e0e0',
                      borderRadius: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        cursor: booking.trainer ? 'pointer' : 'default',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                      onClick={() => {
                        if (booking.trainer) {
                          navigate(
                            `/trainer/${booking.trainer.trainerUuid}/profile`
                          )
                        }
                      }}
                    >
                      <Avatar
                        src={
                          booking.trainer
                            ? (booking.trainer.account.profilePictureUrl ?? '')
                            : ''
                        }
                        sx={{ width: 24, height: 24 }}
                      />
                      {booking.trainer
                        ? `${booking.trainer.account.firstName} ${booking.trainer.account.lastName}`
                        : `${booking.learner.account.firstName} ${booking.learner.account.lastName}`}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          window.open(
                            getGoogleMapsLink(booking.bowlingCenter),
                            '_blank'
                          )
                        }}
                      >
                        <LocationOnIcon fontSize="small" color="info" />
                        <Typography variant="body2" color="text.secondary">
                          {booking.bowlingCenter.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <CalendarTodayIcon fontSize="small" color="action" />
                        <Typography variant="body2" color="text.secondary">
                          {dayjs(booking.startDatetime).format('MMM D, YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <AccessTimeIcon fontSize="small" color="action" />
                        <Typography variant="body2" color="text.secondary">
                          {dayjs(booking.startDatetime).format('h:mm A')} -{' '}
                          {dayjs(booking.endDatetime).format('h:mm A')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default UpcomingBookings

import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E140C',
    },
    secondary: {
      main: '#E0C2FF',
    },
    text: {
      primary: '#1E140C',
      secondary: '#8E8985',
    },
  },
  typography: {
    fontFamily: 'Bricolage Grotesque, sans-serif',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: '#C7C4C2',
          borderWidth: '2px',
          borderRadius: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
})

export default theme

const TRAINER_API_URL = `${import.meta.env.VITE_API_BASE_URL}/trainer`

async function getTrainersForAdmin() {
  const response = await fetch(`${TRAINER_API_URL}/all`, {
    method: 'GET',
    credentials: 'include',
  })
  return response
}

async function getTrainerForAdmin(trainerUuid: string) {
  const response = await fetch(`${TRAINER_API_URL}/${trainerUuid}`, {
    method: 'GET',
    credentials: 'include',
  })
  return response
}

export { getTrainerForAdmin, getTrainersForAdmin }

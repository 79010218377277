import BowlingCenter from './BowlingCenter'
import { Trainer } from './Trainer'

export class TrainerProfile
  implements
    Omit<Trainer, 'accountUuid' | 'verificationStatus' | 'stripeAccountId'>
{
  trainerUuid: string
  firstName: string
  lastName: string
  profilePictureUrl: string
  defaultBowlingCenterUuid: string
  bowlingCenters: BowlingCenter[]
  reviewCount: number
  averageRating: string
  videoUrls: string[]
  certifications: string[] | null
  bowlingStyles: string[] | null
  about: string | null
  hourlyRate: number | null

  constructor({
    trainerUuid,
    firstName,
    lastName,
    profilePictureUrl,
    defaultBowlingCenterUuid,
    bowlingCenters,
    reviewCount,
    averageRating,
    videoUrls,
    certifications = [],
    bowlingStyles = [],
    about = null,
    hourlyRate = null,
  }: {
    trainerUuid: string
    firstName: string
    lastName: string
    profilePictureUrl: string
    defaultBowlingCenterUuid: string
    bowlingCenters: BowlingCenter[]
    reviewCount: number
    averageRating: string
    videoUrls: string[]
    certifications: string[] | null
    bowlingStyles: string[] | null
    about: string | null
    hourlyRate: number | null
  }) {
    this.trainerUuid = trainerUuid
    this.firstName = firstName
    this.lastName = lastName
    this.profilePictureUrl = profilePictureUrl
    this.defaultBowlingCenterUuid = defaultBowlingCenterUuid
    this.bowlingCenters = bowlingCenters
    this.reviewCount = reviewCount
    this.averageRating = averageRating
    this.videoUrls = videoUrls
    this.certifications = certifications
    this.bowlingStyles = bowlingStyles
    this.about = about
    this.hourlyRate = hourlyRate
  }

  get defaultBowlingCenter(): BowlingCenter | null {
    return (
      this.bowlingCenters.find(
        (center) => center.bowlingCenterUuid === this.defaultBowlingCenterUuid
      ) ?? null
    )
  }
}
